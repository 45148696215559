
<script setup>
    import { watch, reactive, onMounted } from "vue";
    import VueRouter from "vue-router";

    const route = VueRouter.useRoute();
    const router = VueRouter.useRouter();

    const data = reactive({});
    
    function getRouteText(path) {
        const backRoute = router.options.routes.filter(e => e.path === path)[0];
        if (backRoute) {
            const textLength = route.meta.title.length*1.0 + backRoute.meta.title.length*0.7;
            if (textLength < 15) {
                return backRoute.meta.title;
            } else {
                return $t("Back");
            }
        }
        return $t("Back");
    }

    watch(route, () => {
        const state = router.options.history.state;

        // TODO: find a way to fix iframe inside vue router issue
        if (state.current === "/bim") {
            data.text = $t("Home");
            data.func = () => router.replace("/");
            return;
        }

        // TODO: find a way to delete history when clicking home button
        if (state.current === "/") {
            data.text = null;
            data.func = null;
            return;
        }

        if (state.back != null) {
            // there is history, so we just go back
            data.text = getRouteText(state.back);
            data.func = () => router.back();
        } else {
            if (state.current === "/") {
                // there is no history and we are at root, don't show back button
                data.text = null;
                data.func = null;
            } else {
                // there is no history, but we are not at root, go one step up
                const newRoute = ("/" + state.current.split("/").slice(0, -1).join("/")).replace("//", "/");
                data.text = getRouteText(newRoute);
                data.func = () => router.replace(newRoute);
            }
        }
    });
</script>

<template>
    <transition :name="$route.meta.transition" :css="$route.meta.transition != null">
        <div :key="$route.path" style="position: absolute;">
            <a class="d-flex align-items-center" style="padding-left: 0.75rem; height: 100%; color: white; text-decoration: none; font-size: 0.9em; white-space: nowrap; gap: 0.25rem;" @click="data?.func()" v-if="data.func">
                <i class="bi bi-chevron-left"></i>
                <span>{{ data.text }}</span>
            </a>
        </div>
    </transition>
</template>
